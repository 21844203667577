import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const seriesOptions = [
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
];

const InvestorPreferences = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [preferences, setPreferences] = useState({
    minAnnualRevenue: "",
    maxAnnualRevenue: "",
    minWorkingCapital: "",
    preferredSeries: [],
    investmentSizeRange: "",
  });

  const handleInputChange = (event) => {
    setPreferences({ ...preferences, [event.target.name]: event.target.value });
  };

  const handleSeriesChange = (event) => {
    const {
      target: { value },
    } = event;
    setPreferences({
      ...preferences,
      preferredSeries: typeof value === "string" ? value.split(",") : value,
    });
  };

  const applyPreferences = () => {
    console.log("Applying preferences:", preferences);
    // Logic to apply these preferences
  };

  return (
    <Box m="20px">
      <Header
        title="Investor Preferences"
        subtitle="Customize your investment criteria"
      />

      <Box
        sx={{
          p: 2,
          backgroundColor: colors.primary[400],
          borderRadius: "10px",
        }}
      >
        <TextField
          label="Minimum Annual Revenue"
          name="minAnnualRevenue"
          type="number"
          fullWidth
          sx={{ mb: 3 }}
          value={preferences.minAnnualRevenue}
          onChange={handleInputChange}
        />

        <TextField
          label="Maximum Annual Revenue"
          name="maxAnnualRevenue"
          type="number"
          fullWidth
          sx={{ mb: 3 }}
          value={preferences.maxAnnualRevenue}
          onChange={handleInputChange}
        />

        <TextField
          label="Minimum Working Capital"
          name="minWorkingCapital"
          type="number"
          fullWidth
          sx={{ mb: 3 }}
          value={preferences.minWorkingCapital}
          onChange={handleInputChange}
        />

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Preferred Series</InputLabel>
          <Select
            multiple
            name="preferredSeries"
            value={preferences.preferredSeries}
            onChange={handleSeriesChange}
            input={<OutlinedInput label="Preferred Series" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {seriesOptions.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Minimum Investment Size"
          name="minInvestmentSize"
          type="number"
          fullWidth
          sx={{ mb: 3 }}
          value={preferences.minInvestmentSize}
          onChange={handleInputChange}
        />

        <TextField
          label="Maximum Investment Size"
          name="maxInvestmentSize"
          type="number"
          fullWidth
          sx={{ mb: 3 }}
          value={preferences.maxInvestmentSize}
          onChange={handleInputChange}
        />

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={applyPreferences}
            sx={{
              backgroundColor: colors.greenAccent[700],
              "&:hover": { backgroundColor: colors.greenAccent[800] },
            }}
          >
            Apply Preferences
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InvestorPreferences;
